<template>
  <v-container fluid>
    <v-card outlined>
      <v-tabs v-model="tab">
        <v-tab v-for="group in filterGroups" :key="group.value">
          {{ group.text }}
        </v-tab>
      </v-tabs>
    </v-card>

    <v-card outlined class="pa-5 mt-3">
      <v-toolbar flat>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          outlined
          dense
          class="shrink search"
          :class="$vuetify.breakpoint.xsOnly ? 'seachXs' : ''"
          :placeholder="$t('search')"
          :label="$t('search')"
          single-line
          hide-details
        />
        <v-spacer></v-spacer>
        <v-btn
          :v-if="$store.getters.isInRole(54)"
          color="primary"
          elevation="0"
          class="ma-1"
          @click="dialog = true"
        >
          {{ $t("add") }}

          <v-icon> mdi-plus </v-icon>
        </v-btn>
      </v-toolbar>

      <data-table-component
        :headers="headers"
        :items="charges"
        :search="search"
        :loading="loading"
        :showFooter="false"
      />
      <v-dialog v-model="dialog" persistent max-width="600px">
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-card>
            <v-toolbar color="primary darken-1" dark>
              <v-card-title>
                <span class="">{{
                  (editedIndex === -1 ? $t("add") : $t("edit")) +
                  " " +
                  $t("services.serviceCharge")
                }}</span>
              </v-card-title>
            </v-toolbar>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" md="6">
                    <v-autocomplete
                      v-model="editedItem.serviceGuid"
                      :rules="rules"
                      :items="services"
                      :label="$t('services.serviceName')"
                      required
                      item-text="serviceName"
                      item-value="guid"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-autocomplete
                      v-model="editedItem.doctorGuid"
                      :items="doctors"
                      :label="$t('doctors.doctorName')"
                      required
                      item-text="doctorName"
                      item-value="guid"
                    ></v-autocomplete>
                  </v-col>

                  <v-col cols="12" md="6">
                    <v-text-field
                      v-model="editedItem.servicePrice"
                      :label="$t('services.servicePrice')"
                      type="number"
                      :rules="rules"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" md="6">
                    <v-text-field
                      v-model="editedItem.doctorAmount"
                      :label="$t('services.doctorAmount')"
                      type="number"
                      min="0"
                      :rules="[
                        (value) =>
                          !!value || value == 0 || $t('ThisFieldIsRequired'),
                      ]"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="11" md="11">
                    <v-slider
                      v-model="editedItem.doctorPercentage"
                      color="orange"
                      min="0"
                      max="100"
                      persistent-hint
                      :label="$t('services.doctorPercentage')"
                      :thumb-size="24"
                      thumb-label="always"
                    ></v-slider>
                    <!-- </v-card-text> -->
                  </v-col>
                  <v-col cols="1" md="1" class="pa-0 pt-3 font-weight-medium">
                    <div>{{ editedItem.doctorPercentage + " %" }}</div>
                  </v-col>

                  <v-col cols="12" class="pt-0">
                    <v-textarea
                      v-model="editedItem.serviceChargeNote"
                      :label="$t('notes')"
                      rows="2"
                    ></v-textarea>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-btn
                color="green darken-1"
                class="white--text"
                @click="save"
                :disabled="!valid"
                :min-width="100"
              >
                <v-icon>mdi-content-save-outline</v-icon>
                {{ $t("save") }}
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn color="red" text @click="close">
                {{ $t("cancel") }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-dialog>
    </v-card>
    <confirm-dialog
      :openDialog="dialogDelete"
      :onClicked="deleteItemConfirm"
      :onClose="closeDelete"
    ></confirm-dialog>
  </v-container>
</template>
<script>
import ConfirmDialog from "@/components/ConfirmDialog.vue";
import DataTableComponent from "@/components/DataTableComponent.vue";
export default {
  components: { ConfirmDialog, DataTableComponent },
  data() {
    return {
      tab: 0,
      valid: true,
      dialog: false,
      dialogDelete: false,
      loading: false,
      editedIndex: -1,
      search: "",
      charges: [],
      editedItem: {},
      defaultItem: {
        serviceGuid: "",
        doctorGuid: "",
        servicePrice: null,
        doctorAmount: null,
        doctorPercentage: 0,
        serviceChargeNote: "",
      },

      groups: [
        {
          text: this.$t("services.serviceGroupType.general"),
          value: 0,
          role: "65",
        },
        {
          text: this.$t("services.serviceGroupType.dental"),
          value: 1,
          role: "66",
        },
        {
          text: this.$t("services.serviceGroupType.operation"),
          value: 2,
          role: "67",
        },
      ],
      services: [],
      doctors: [],
      rules: [(value) => !!value || this.$t("ThisFieldIsRequired")],
    };
  },
  created() {
    this.refreshTable();
  },

  watch: {
    dialog(val) {
      if (!val) this.close();

      if (this.editedIndex == -1) this.editedItem.doctorAmount = 0;
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    tab() {
      this.refreshTable();
    },
    pageLength(val) {
      if (this.page > val) this.page = val;
    },
  },
  computed: {
    headers() {
      var list = [
        { text: this.$t("services.serviceName"), value: "serviceName" },
        {
          text: this.$t("doctors.doctorName"),
          value: "doctorGuid",
          type: "select",
          items: this.doctorsList,
        },
        {
          text: this.$t("services.servicePrice"),
          value: "servicePrice",
          type: "number",
          format: "0,0",
        },
        {
          text: this.$t("services.doctorAmount"),
          value: "doctorAmount",
          type: "number",
          format: "0,0",
        },
        {
          text: this.$t("services.doctorPercentage"),
          value: "doctorPercentage",
          type: "chip",
          suffix: "%",
        },
        { text: this.$t("notes"), value: "serviceChargeNote" },
      ];
      var actions = {
        text: "",
        value: "actions",
        templates: [],
      };

      if (this.$store.getters.isInRole(55)) {
        actions.templates.push({
          type: "btn",
          icon: "mdi-pencil",
          text: this.$t("edit"),
          click: this.editItem,
        });
      }
      if (this.$store.getters.isInRole(56)) {
        actions.templates.push({
          type: "btn",
          icon: "mdi-delete",
          text: this.$t("delete"),
          click: this.deleteItem,
          color: "red darken-2",
        });
      }
      list.push(actions);
      return list;
    },
    doctorsList() {
      console.log(this.doctors);
      if (this.doctors && this.doctors.length > 0) {
        var list = [];
        for (var i = 0; i < this.doctors.length; i++) {
          list.push({
            text: this.doctors[i].doctorName,
            value: this.doctors[i].guid,
          });
        }
        return list;
      }
      return [{ text: this.$t("services.allDoctor"), value: "" }];
    },
    filterGroups() {
      return this.groups.filter((f) => this.isInRole(f.role));
    },
  },
  methods: {
    getText(i) {
      var result = this.groups.filter((obj) => {
        return obj.value == i;
      });
      return result[0].text;
    },
    editItem(item) {
      this.editedIndex = this.charges.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.charges.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      var deleteItem = this.charges[this.editedIndex];
      this.$axios
        .delete("ServiceCharge/Delete?guid=" + deleteItem.guid)
        .then((response) => {
          if (response.status == 200) this.refreshTable();
        });
      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
      this.$refs.form.reset();
      this.$refs.form.resetValidation();
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    refreshTable() {
      this.loading = true;
      this.$axios
        .get("ServiceCharge/Get?group=" + this.filterGroups[this.tab].value)
        .then((response) => {
          this.charges = response.data.data;

          if (this.isInRole("6")) {
            this.$axios.get("doctor").then((response) => {
              this.doctors = response.data.data;
              this.doctors.push({
                guid: null,
                doctorName: this.$t("services.allDoctor"),
              });
            });
          } else {
            this.doctors.push({
              guid: "",
              doctorName: this.$t("services.allDoctor"),
            });
          }

          this.$axios
            .get("service/Get?group=" + this.filterGroups[this.tab].value)
            .then((response) => {
              this.services = response.data.data;
            });
        })
        .finally(() => {
          this.loading = false;
        });
    },

    save() {
      var val = this.$refs.form.validate();
      if (val) {
        if (this.editedIndex > -1) {
          var updatedItem = this.charges[this.editedIndex];
          updatedItem.serviceGuid = this.editedItem.serviceGuid;
          updatedItem.doctorGuid = this.editedItem.doctorGuid;
          updatedItem.servicePrice = this.editedItem.servicePrice;
          updatedItem.doctorAmount = this.editedItem.doctorAmount;
          updatedItem.doctorPercentage = this.editedItem.doctorPercentage;
          updatedItem.serviceChargeNote = this.editedItem.serviceChargeNote;

          this.$axios
            .post("ServiceCharge/Update", updatedItem)
            .then((response) => {
              if (response.data.status == "Successful") {
                this.$toast(this.$t("operationAccomplishedSuccessfully"));
                this.close();
              } else {
                this.$toast.error(this.$t("error." + response.data.message));
              }

              this.refreshTable();
            })
            .catch((e) => {
              this.$toast.error(this.$t("AnErrorOccurredDuringTheProcess"));
              console.log(e);
            });

          Object.assign(this.charges[this.editedIndex], this.editedItem);
        } else {
          this.$axios
            .post("ServiceCharge/Add", this.editedItem)
            .then((response) => {
              if (response.data.status == "Successful") {
                this.$toast(this.$t("operationAccomplishedSuccessfully"));
                this.close();
              } else {
                this.$toast.error(this.$t("error." + response.data.message));
              }

              this.refreshTable();
            })
            .catch((e) => {
              this.$toast.error(this.$t("AnErrorOccurredDuringTheProcess"));
              console.log(e);
            });
        }
      }
    },
  },
};
</script>

<style scoped>
.Percentage {
  height: unset;
  padding: 0;
  margin-top: -20px;
  margin-bottom: 20px;
}
</style>
